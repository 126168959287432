/* eslint-disable prefer-destructuring */
<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form
            ref="form"
            @submit.prevent="repeateAgain"
          >

            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >

              <!-- Item Name -->
              <b-col md="3">
                <b-form-input
                  v-model="item.field"
                  type="text"
                  :placeholder="$t('TRADUCTION_FIELD')"
                />
              </b-col>

              <b-col md="3">
                <b-form-input
                  v-model="item.traduction"
                  type="text"
                  :placeholder="$t('TRADUCTION_TRADUCTION')"
                />
              </b-col>

              <b-col md="3">
                <v-select
                  v-model="item.langue"
                  :options="langues"
                  :clearable="false"
                />

              </b-col>

              <!-- Remove Button -->
              <b-col
                md="3"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('REMOVE_LINE_BUTTON') }}</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </b-form>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('ADD_NEW_LINE') }}</span>
          </b-button>

          <center>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="ml-1"
              variant="success"
              @click="save"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-25"
              />
              <span>{{ $t('INSERT_TRADUCTION_BUTTON') }}</span>
            </b-button>
          </center>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm, BFormInput, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: 1,
        langue: {},
        field: '',
        traduction: '',
      }],
      nextTodoId: 2,
      langues: [],
    }
  },
  mounted() {
    this.$store.dispatch('localeTraduction/fetchLocales').then(r => {
      this.langues = r.map(obj => {
        const rObj = {}
        rObj.value = obj['@id']
        rObj.label = obj.name
        return rObj
      })

      // eslint-disable-next-line prefer-destructuring
      this.items[0].langue = this.langues[0]
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        langue: this.langues[0],
        field: '',
        traduction: '',
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    save() {
      const that = this
      this.$store.dispatch('localeTraduction/postTraductions', this.items)
        .then(data => {
          ['fr', 'en'].forEach(locale => {
            that.$i18n.setLocaleMessage(locale, data.traduction_update[locale])
          })

          // On affiche le resultat
          that.show_resultPopup(data, that)
        })
    },
    show_resultPopup(data, that) {
      // eslint-disable-next-line no-param-reassign
      that.items = that.items.filter(el => data.lignes[el.id].result === 'error')

      if (that.items.length === 0) {
        // eslint-disable-next-line no-param-reassign
        that.items = [{
          id: 1,
          langue: {},
          field: '',
          traduction: '',
        }]
      }

      this.$swal(
        {
          title: `${this.$i18n.t('TRADUCTION_INSERT_POPUP')}`,
          html: `${this.$i18n.t('TRADUCTION_INSERT_SUCCESS')}: <small>${data.insert}.</small><br />
                 ${this.$i18n.t('TRADUCTION_INSERT_ERROR')}: <small>${data.error}.</small><br />
                 ${this.$i18n.t('TRADUCTION_INSERT_UPDATED')}: <small>${data.update}.</small><br />`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
